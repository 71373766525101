<template>
  <tr :id="rowId" v-if="!!item">
    <td>
      <router-link :to="userLink" class="link" title="عرض">{{
          item.user_name
        }}
      </router-link>
    </td>
    <td>
      <span v-if="!!item.insurance" class="link"
      >{{ item.insurance }}&nbsp;دينار</span
      >
      <span v-else>لا يوجد</span>
    </td>
    <td>
      <modal-btn :target="`#${imagesId}`" btn-class="btn btn-sm btn-primary">
        عرض
      </modal-btn>
    </td>
    <td>
      <button data-toggle="modal" :data-target="`#${activateId}`" class="btn btn-sm btn-info rounded-0 mr-sm-1">
        تفعيل
      </button>
      <modal-btn :target="`#${deleteId}`" btn-class="btn btn-sm btn-soft-danger">
        إزالة
      </modal-btn>
    </td>
    <teleport to="#app">
      <InsurancesImages :insurances="insurances_images" :target-id="imagesId"/>
      <items-destroyer
          title="حذف طلب تفعيل"
          :target-id="deleteId"
          :row-id="rowId"
          :url="deleteLink"
          @deleted="deleted">
        هل أنت متأكد من إزالة طلب التفعيل للمستخدم <br/>
        <span class="link">{{ item.user_name }}</span>&nbsp;؟
      </items-destroyer>
      <confirm-model
          :target-id="activateId"
          title="تفعيل المستخدم"
          header-bg="bg-info"
          confirm-text="تفعيل"
          @confirmed="activate"
      >
        <p class="mb-5 text-muted">يرجى اختيار مبلغ التامين لتفعيل الحساب</p>
        <BootstrapSelect
            :id="`status-${item.id}`"
            :options="options"
            :empty-option="false"
            @changed="changed"
        />
      </confirm-model>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import InsurancesImages from "@/components/Users/InsurancesImages";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import ConfirmModel from "@/components/UI/ConfirmModel";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: {
    BootstrapSelect,
    ConfirmModel,
    ItemsDestroyer,
    InsurancesImages,
    ModalBtn,
  },
  props: {item: {required: true}, insurances: {required: true}},
  emits: ["deleted", "updated"],
  data() {
    return {
      options: [],
      insurance: null,
    };
  },
  computed: {
    rowId() {
      return !this.item ? "" : `activation-${this.item.request_id}`;
    },
    deleteId() {
      return !this.item ? "" : `activation-delete-${this.item.request_id}`;
    },
    imagesId() {
      return !this.item ? "" : `activation-images-${this.item.id}`;
    },
    userLink() {
      return !this.item
          ? ""
          : {name: "users.edit", params: {user: this.item.id}};
    },
    deleteLink() {
      return !this.item ? "" : `/activation-requests/${this.item.request_id}`;
    },
    insurances_images() {
      if (!Array.isArray(this.item.ins_images) || this.item.ins_images.length === 0) {
        return []
      }

      return [this.item.ins_images[0]]
    },
    activateId() {
      return !this.item ? "" : `activate_${this.item.request_id}`;
    },
  },
  methods: {
    deleted(id) {
      this.$emit("deleted", id);
    },
    async activate() {
      if (!this.insurance) {
        return http.errorMessage("يرجى تحديد مبلغ التأمين");
      }

      const response = await http.send("/user/activation", {
        status: this.insurance,
        user: this.item.id,
        request_id: this.item.request_id,
      });

      if (http.responseAccepted(response)) {
        http.successMessage("تم تعديل تأمين المستخدم بنجاح");
        this.$emit("updated", this.item.request_id);

        this.hideModal(`#${this.activateId}`);
      }
    },
    changed(value) {
      this.insurance = value;
    },
  },
  created() {
    this.options = this.insurances;
  },
};
</script>
