<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered" :class="{'shadow-sm': !emptyList}">
            <tr class="bg-bb">
              <th>الاسم الكامل</th>
              <th>تأمينه الحالي</th>
              <th>صور التأمين</th>
              <th>التفعيل</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="5" class="font-weight-bolder">لا يوجد</td>
            </tr>
            <ActivationItem
              v-else
              v-for="item in list"
              :key="item.id"
              :item="item"
              :insurances="insurances"
              @deleted="requestDeleted"
              @updated="requestDeleted"
            />
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActivationItem from "@/components/Users/ActivationItem";
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import Storage from "@/common/Storage";
import types from "@/common/types";

export default {
  components: {ThePaginator, ActivationItem},
  mixins: [pagination],
  data() {
    return {
      insurances: []
    }
  },
  computed: {
    defaultUrl() {
      return 'activation-requests';
    },
    insurancesStorageKey() {
      return 'activation-Insurances'
    },
  },
  methods: {
    async requestDeleted(id) {
      await this.deleted(id, 'request_id')
      this.$store.dispatch('setUserActivationAction', this.length)
    },
    async setInsurances() {
      const storedInsurances = Storage.get(this.insurancesStorageKey)
      if (!!storedInsurances && !!storedInsurances.data) {
        this.insurances = types.objectToArray(storedInsurances.data)
      } else {
        const response = await http.send('/insurances-get', {_method: 'GET'})
        if (http.responseAccepted(response)) {
          this.insurances = response.data.insurances
          Storage.set(this.insurancesStorageKey, this.insurances)
        }
      }

      this.insurances.push({id: -1, content: 'إلغاء التأمين'})
    }
  },
  async created() {
    await this.setInsurances()
    await this.setList();
  }
}
</script>